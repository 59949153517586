import React, { useState } from "react";
import "./sidebar.css"; // Custom CSS for styling

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ul className="list-unstyled mt-4">
        <li className="py-2 px-3">
        <a href="/Invoices" className="text-white text-decoration-none">
            <i className="bi bi-receipt"></i> Invoices
        </a>
        </li>
        <li className="py-2 px-3">
        <a href="/products" className="text-white text-decoration-none">
        <i className="bi bi-box"></i> Products
        </a>
        </li>
        <li className="py-2 px-3">
        <a href="/categories" className="text-white text-decoration-none">
        <i className="bi bi-inboxes"></i> Categories
        </a>
        </li>
        <li className="py-2 px-3">
        <a href="/customers" className="text-white text-decoration-none">
        <i className="bi bi-people"></i> Customers
        </a>
        </li>
        <li className="py-2 px-3">
        <a href="/gstrates" className="text-white text-decoration-none">
        <i className="bi bi-calculator"></i> GST Rate
        </a>
        </li>
    </ul>
  );
};

export default Sidebar;

import './App.css';
import React from 'react';
import { Routes,Route} from 'react-router-dom';

import ProductDropdown from './component/includes/ProductDropdown';
import CustomerDropdown from './component/includes/CustomerDropdown';
import ProductRow from './component/includes/ProductRow';
import InvoiceForm from './component/includes/InvoiceForm';
import GstRateList from './dashboard/GstRate';
import CategoryList from './dashboard/Categories';
import ProductList from './dashboard/ProductsList';
import CustomerList from './dashboard/CustomerList';
import InvoiceList from './dashboard/InvoiceList_bkp.js';

import InvoiceDetail from './dashboard/DetailPage.js';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<InvoiceList/>} />
        <Route path='/invoices' element={<InvoiceList/>} />
        <Route path='/invoice/:id' element={<InvoiceDetail/>} />
        <Route path='/products' element={<ProductList/>} />
        <Route path='/categories' element={<CategoryList/>}/>
        <Route path='/gstrates' element={<GstRateList/>} />
        <Route path='/customers' element={<CustomerList/>}/>
        <Route path='/productdrodown' element={<ProductDropdown/>} />
        <Route path='/cutomerdrodown' element={<CustomerDropdown/>} />
        <Route path='/productrow' element={<ProductRow/>} />
        <Route path='/invoiceForm' element={<InvoiceForm/>} />
      </Routes>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import PaginatedList from "./PaginatedList";
import TodayDate from "../component/form/formatDate";
import Select from 'react-select';
import Sidebar from "./Sidebar";

const InvoiceList = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const [customerDetails, setCustomerDetails] = useState({
      email: "",
      phone: "",
      address: "",
      gstin: "",
  });
// Fetch products from API
useEffect(() => {
    const fetchProducts = async () => {
        try {
            const response = await fetch('https://standskills.co.in/riddhimaagency/public/api/products');
            const data = await response.json();
            setProducts(data);  // Assuming the API returns product data directly
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setLoading(false);
        }
    };
    fetchProducts();
}, []);
// Fetch customers from API
useEffect(() => {
    const fetchCustomers = async () => {
        try {
            const response = await fetch('https://standskills.co.in/riddhimaagency/public/api/customers');  // Replace with actual endpoint
            const data = await response.json();
            setCustomers(data);  // Assuming the API returns customer data directly
        } catch (error) {
            console.error('Error fetching customers:', error);
        } finally {
            setLoading(false);
        }
    };
    fetchCustomers();
}, []);
// Format data for react-select
const productOptions = products.map((product) => ({
    value: product.id,  // Product ID
    label: product.name,  // Product name to display
}));
//Customers Section
const customerOptions = customers.map((customer) => ({
    value: customer.id,  // Customer ID
    label: customer.name,  // Customer name to display
}));
const handleCustomerChange = (selectedOption) => {  
  setInvoiceFromData({ ...invoiceFromData, customer_id: selectedOption.value });
  // Clear the error for customer_id
  setErrors((prevErrors) => {
    const newErrors = { ...prevErrors };
    if (newErrors.customer_id) {
      delete newErrors.customer_id;
    }
    return newErrors;
  });
  // Update customer details based on the selected customer
  const selectedCustomer = customers.find((customer) => customer.id === selectedOption.value);
  if (selectedCustomer) {
      setCustomerDetails({
          email: selectedCustomer.email || "",
          phone: selectedCustomer.phone || "",
          address: selectedCustomer.address || "",
          gstin: selectedCustomer.gstin || "",
      });
  }
};
//Product Section
const handleProductChange = (index, selectedOption) => {
  const selectedProduct = products.find((product) => product.id === selectedOption.value);
  const updatedItems = [...invoiceFromData.items];
  updatedItems[index].product_id = selectedProduct.id;
  //updatedItems[index].product_details = selectedProduct;  // Save product details
  updatedItems[index].mrp = selectedProduct.mrp;
  updatedItems[index].discount = 0;
  //updatedItems[index].rate = selectedProduct.mrp;
  updatedItems[index].rate = 
  ((updatedItems[index].mrp * (100 - updatedItems[index].discount) / 100) / 
   (1 + updatedItems[index].gst_rate / 100)).toFixed(2);//  updatedItems[index].rate = Math.round(selectedProduct.mrp - selectedProduct.discount);
  updatedItems[index].gst_rate = selectedProduct.gst_rate;

  // Recalculate the subtotal, tax, and total
  //updatedItems[index].subtotal = updatedItems[index].rate * updatedItems[index].quantity;
  updatedItems[index].subtotal = Math.round((updatedItems[index].rate * updatedItems[index].quantity) * 100) / 100;
  updatedItems[index].tax_amount = (updatedItems[index].subtotal * updatedItems[index].gst_rate) / 100;
  updatedItems[index].total = updatedItems[index].subtotal;//updatedItems[index].subtotal - updatedItems[index].discount + updatedItems[index].tax_amount;

  setInvoiceFromData({ ...invoiceFromData, items: updatedItems });
};
const handleItemChange = (index, field, value) => {
  const updatedItems = [...invoiceFromData.items];
  updatedItems[index][field] = value;

  //Update Rate Based on discount
  updatedItems[index].rate = 
  ((updatedItems[index].mrp * (100 - updatedItems[index].discount) / 100) / 
   (1 + updatedItems[index].gst_rate / 100)).toFixed(2);//((updatedItems[index].mrp - (updatedItems[index].discount / 100 * updatedItems[index].mrp)) * 100 / 100).toFixed(2);
  // Calculate subtotal for the item: rate * quantity
  updatedItems[index].subtotal = parseFloat((updatedItems[index].rate * updatedItems[index].quantity).toFixed(2)); 
  // Calculate tax and total for the item
  updatedItems[index].tax_amount = parseFloat(((updatedItems[index].subtotal * updatedItems[index].gst_rate) / 100).toFixed(2));
  //updatedItems[index].total = updatedItems[index].subtotal - updatedItems[index].discount + updatedItems[index].tax_amount;
  updatedItems[index].total = updatedItems[index].subtotal;

  setInvoiceFromData({ ...invoiceFromData, items: updatedItems });
};
const addItem = () => {
  setInvoiceFromData({
      ...invoiceFromData,
      items: [
          ...invoiceFromData.items,
          { product_id: "",mrp: 0, rate: 0, discount: 0, quantity: 1, gst_rate: 0, tax_amount: 0, subtotal: 0, total: 0 },
      ],
  });
};
const removeItem = (index) => {
  const updatedItems = invoiceFromData.items.filter((_, i) => i !== index);
  setInvoiceFromData({ ...invoiceFromData, items: updatedItems });
};
// Setting Invoice Form data
const [invoiceFromData, setInvoiceFromData] = useState({
  invoice_number: "",
  customer_id: "",
  total_amount: "",
  gst_amount: "",
  grand_total: "",
  status: "", 
  items: [
      { product_id: "", mrp: 0, rate: 0, discount: 0, quantity: 1, gst_rate: 0,tax_amount:0, subtotal: 0, total: 0 },
  ],
});
// Errors state for validation
const [errors, setErrors] = useState({});
  // Handle input change
const handleInvoiceFormChange = (e) => {
    const { name, value } = e.target;
    setInvoiceFromData({
      ...invoiceFromData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" }); // Clear error for the field
};
  // Validate form
  const validateForm = () => {
    const newErrors = {};
    if (!invoiceFromData.invoice_number) newErrors.invoice_number = "Invoice No is required";
    if (!invoiceFromData.customer_id) newErrors.customer_id = "Customer Details is required";
    //if (!invoiceFromData.total_amount) newErrors.total_amount = "Total is required";
    //if (!invoiceFromData.gst_amount) newErrors.gst_amount = "Tax Amt is required";
    //if (!invoiceFromData.grand_total) newErrors.grand_total = "Grand Total is required";
    return newErrors;
  };

 
  // Handle Add Invoice
  const handleAddInvoice = async (e) => {
    e.preventDefault();


    console.log(invoiceFromData);
    //console.log(customers);
    //console.log(products);



    const validationErrors = validateForm();

    console.log(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await fetch(
        "https://standskills.co.in/riddhimaagency/public/api/invoices",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(invoiceFromData),
        }
      );

      if (response.ok) {
        alert("Invoice added successfully!");
        //fetchInvoices();
        // setInvoiceFromData({
        //   invoice_number: "",
        //   customer_id: "",
        //   total_amount: "",
        //   gst_amount: 0,
        //   grand_total: 0,
        //   items: [
        //       { product_id: "",mrp: 0, rate: 0, discount: 0, quantity: 1, gst_rate: 0, gst_amount: 0, subtotal: 0, total: 0 },
        //   ],
        //   });
        setErrors({});
      } else {
        alert("Failed to add invoice.");
      }
    } catch (error) {
      console.error("Error adding invoice:", error);
    }
  };


//Edit 
const [selectedItem, setSelectedItem] = useState(null);
const handleGetInvoice = (item) => {
    setSelectedItem(item);
    setInvoiceFromData({
      ...invoiceFromData,
      id: item.id || "",
      invoice_number: item.invoice_number || "",
      customer_id: item.customer_id || "",
      total_amount: item.total_amount || "",
      gst_amount: item.gst_amount || "",
      grand_total: item.grand_total || "",
    });
}






    // // Calculate the grand totals
    const totalAmount = invoiceFromData.items.reduce((sum, item) => sum + item.total, 0);
    const taxAmount = invoiceFromData.items.reduce((sum, item) => sum + item.tax_amount, 0);
    const grandTotal = totalAmount + taxAmount;

  return (
    <>
      <PaginatedList
        fetchUrl="https://standskills.co.in/riddhimaagency/public/api/invoices"
        columns={[
          { key: "invoice_number", label: "Invoice NO" },
          { key: "customer_id", label: "Customer" },
          { key: "total_amount", label: "Total Amount" },
          { key: "gst_amount", label: "Tax Amount" },
          { key: "grand_total", label: "Grand Total" },
          {
            key: "created_at",
            label: "Created At",
            render: (item) => (
                <div className="action-buttons">
                {/* <button className="btn btn-success btn-sm me-2"><i Name="bi bi-plus-circle"></i></button> */}
                <button className="btn btn-warning btn-sm me-2" onClick={() => handleGetInvoice(item)}><i class="bi bi-eye-fill"></i></button>
                <button className="btn btn-warning btn-sm me-2" onClick={() => handleGetInvoice(item)}><i className="bi bi-pencil-square"></i></button>
                <button className="btn btn-danger btn-sm me-2"><i className="bi bi-trash3-fill"></i></button>
                </div>
            ),
            },
        ]}
        title="Invoice List"
      />
    <button className="btn btn-primary toggle-drawer-btn" onClick={() => setIsDrawerOpen(!isDrawerOpen)} style={{position:"fixed",right:"10px",top:"10px",}}>
        {isDrawerOpen ? <i class="bi bi-x-lg"></i> : <i class="bi bi-file-earmark-plus"></i>}
    </button>
       <div className="drawer bg-light border-left position-fixed end-0 top-0 h-100 p-4" style={{ width: isDrawerOpen ? "80%" : "0", transition: "width 0.3s" }}>
       <div className="invoice">      
       <button
            className="btn btn-light btn-sm"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 1000,
            }}
            onClick={() => setIsDrawerOpen(false)}
          >
            Close
          </button>
        <form onSubmit={handleAddInvoice}>
        <div className="col-md-5 offset-md-7">
              <table className="table table-striped_ inv_head">
                <tbody>
                  <tr>
                    <td className="pritxt"><label className="form-label">Date</label><TodayDate/></td>
                    <td>
                      <label className="form-label">Invoice #</label>
                      <input 
                        name="invoice_number" 
                        type="text" className="form-control"
                        onChange={handleInvoiceFormChange}
                        />
                        {errors.invoice_number && <small className="text-danger">{errors.invoice_number}</small>}
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
          <div className="greybg">
              <table className="tabel" style={{width:"100%"}}>
                <tbody>
                  <tr>
                    <td className="w-50">
                      <p className=""><strong>RIDDHIMA AGENCY</strong></p>
                        <p>GSTIN:22IUNPK1701F1ZX</p>
                        <p>Number:+91 9589786775</p>
                        <p>email:riddhimaagency7@gmail.com</p>
                        <p>Shop 1,  Vrindavan Garden Daldal Seoni Mowa  ,</p>
                        <p>Raipur Chhattisgarh, 492005</p>
                    </td>
                    <td className="w-50">
                      <Select
                            options={customerOptions}
                            onChange={handleCustomerChange}  // Handle customer selection
                            value={customerOptions.find(option => option.value === invoiceFromData.customer_id)}
                          styles={{padding:"0",fontWeight:"bolder"}}

                        />
                      {errors.customer_id && <small className="text-danger">{errors.customer_id}</small>}
                      <p>GSTIN:{invoiceFromData.customer_id ? customerDetails.gstin : ''}</p>
                      <p>Number:{invoiceFromData.customer_id ? customerDetails.phone : ''}</p>
                      <p>GSTIN:{invoiceFromData.customer_id ? customerDetails.email : ''}</p>
                      <p>{invoiceFromData.customer_id ? customerDetails.address : 'Address'}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
          <div>
          <table className="table table-striped_">
              <thead>
                <tr>
                  <th className="invhead"><span >#</span></th>
                  <th className="invhead"><span >Product details</span></th>
                  <th className="invhead"><span >MRP</span></th>
                  <th className="invhead"><span >Rate</span></th>                  
                  <th className="invhead"><span >Dis %</span></th>
                  <th className="invhead"><span >Qty</span></th>
                  <th className="invhead"><span >GST Rate</span></th>
                  {/* <th className="invhead"><span >Gst Amt</span></th> */}
                  <th className="invhead"><span >Amount</span></th>
                  {/* <th className="invhead"><span >Total</span></th> */}
                </tr>
              </thead>
              <tbody>
                {invoiceFromData.items.map((item, index) => (
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td style={{width:"300px"}}>
                            <Select
                                options={productOptions}
                                onChange={(selectedOption) => handleProductChange(index, selectedOption)}
                                value={productOptions.find(option => option.value === item.product_id)}
                            />
                        </td>
                        <td>{item.mrp}</td>
                        <td>
                        {item.rate}
                            {/* <input
                                type="number"
                                className="form-control"
                                name="rate"
                                value={item.rate}
                                onChange={(e) => handleItemChange(index, 'rate', e.target.value)}
                            /> */}
                        </td>
                        <td>
                            <input
                                type="number"
                                className="form-control"
                                name="discount"
                                value={item.discount}
                                onChange={(e) => handleItemChange(index, 'discount', e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                name="quantity"
                                className="form-control"
                                value={item.quantity}
                                onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                name="gst_rate"
                                className="form-control"
                                value={item.gst_rate}
                                onChange={(e) => handleItemChange(index, 'gst_rate', e.target.value)}
                            />
                            <input
                                type="text"
                                name="tax_amount"
                                className="form-control"
                                value={item.tax_amount}
                                onChange={(e) => handleItemChange(index, 'tax_amount', e.target.value)}
                            />
                        </td>
                        <td>
                        { parseFloat(item.subtotal).toFixed(2)}
                            {/* <input
                                type="number"
                                name="subtotal"
                                className="form-control"
                                value={item.subtotal}
                                onChange={(e) => handleItemChange(index, 'subtotal', e.target.value)}
                            /> */}
                        </td>
                        {/* <td style={{textAlign:"right"}}> */}
                        {/* { parseFloat(item.total).toFixed(2)} */}
                            {/* <input
                                type="number"
                                name="total"
                                className="form-control"
                                value={item.total}
                                onChange={(e) => handleItemChange(index, 'total', e.target.value)}
                            /> */}
                        {/* </td> */}
                        <td>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => removeItem(index)}
                            >
                                -
                            </button>
                        </td>
                    </tr>
                ))}
              </tbody>
            </table>
            <button
                    type="button"
                    className="btn btn-primary"
                    onClick={addItem}
                >
                    Add Item
                </button>
          </div>
          {[
            //{ name: "invoice_number", label: "Invoice No." },
            //{ name: "customer_id", label: "Customer Id" },
            //{ name: "total_amount", label: "total_amount" },
            //{ name: "gst_amount", label: "Tax Amount" },
            //{ name: "grand_total", label: "Grand Total" },
          ].map((field) => (
            <div key={field.name} className="mb-3">
              <label htmlFor={field.name} className="form-label">
                {field.label}
              </label>
              <input
                type="text"
                name={field.name}
                className={`form-control ${
                  errors[field.name] ? "is-invalid" : ""
                }`}
                onChange={handleInvoiceFormChange}
                value={invoiceFromData[field.name]}
              />
              {errors[field.name] && (
                <div className="invalid-feedback">{errors[field.name]}</div>
              )}
            </div>
          ))}

          <div className="total col-md-5 offset-md-7">
              <table className="table table-striped_">
                <tbody>
                  <tr>
                    <td><label className="form-label">Total</label></td>
                    <td>
                      <input 
                        name="total_amount" 
                        type="text" className="form-control"
                        onChange={handleInvoiceFormChange}
                        value={totalAmount.toFixed(2)}
                        />
                      </td>
                  </tr>
                  <tr>
                    <td><span>SGST</span></td>
                    <td>
                     {taxAmount.toFixed(2)/2}
                    </td>
                  </tr>
                  <tr>
                    <td><span>CGST</span></td>
                    <td>
                      {taxAmount.toFixed(2)/2}
                    </td>
                  </tr>
                  <tr>
                    <td><span>Total GST</span></td>
                    <td>
                    {taxAmount.toFixed(2)}
                      {/* <input 
                        name="gst_amount" 
                        type="text" className="form-control"
                        onChange={handleInvoiceFormChange}
                        value={taxAmount.toFixed(2)}
                        /> */}
                        {errors.gst_amount && <small className="text-danger">{errors.gst_amount}</small>}
                    </td>
                  </tr>
                  <tr>
                    <td><span>Total</span></td>
                    <td>
                      <input 
                        name="grand_total" 
                        type="text" className="form-control"
                        onChange={handleInvoiceFormChange}
                        value={grandTotal.toFixed(2)}
                        /> 
                        
                    </td>
                  </tr>
                  <tr>
                    <td><span>Paid</span></td>
                    <td>
                      <select 
                        name="status" 
                        type="text" className="form-control"
                        onChange={handleInvoiceFormChange}
                      >
                        <option value="unpaid">Unpaid</option>
                        <option value="paid">Paid</option>
                      </select>    
                    </td>
                  </tr>
                </tbody>
              </table>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
          </div>
        </form>
        </div>
       
      </div>
    </>
  );
};
export default InvoiceList;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams hook to get the URL parameter
import Sidebar from './Sidebar';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const InvoiceDetail = () => {
  const { id } = useParams(); // Get 'id' from the URL parameter
  const [data, setData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
    const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };


  const fetchData = async () => {
    try {
      const response = await fetch(`https://standskills.co.in/riddhimaagency/public/api/invoice/${id}`);
      const detailData = await response.json();
      setData(detailData); // Set the data from API response
    } catch (error) {
      console.error("Error fetching detail data:", error); // Log any errors
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, [id]); // Re-run when the 'id' changes

  const downloadPDF = () => {
    const element = document.getElementById('invoice-content');
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
      pdf.save(`Invoice_${data?.invoice_number || id}.pdf`);
    });
  };

  if (!data) {
    return <p>Loading...</p>; // Display loading until data is fetched
  }

  return (
    <>
    <div className='d-flex'>
    <div
        className={`sidebar bg-dark text-white ${
          isOpen ? "sidebar-open" : "sidebar-closed"
        }`}
      >
        <button
          className="btn btn-light toggle-btn"
          onClick={toggleSidebar}
        >
          {isOpen ? <i className="bi bi-arrow-bar-left"></i> : <i className="bi bi-arrow-bar-right"></i>}
        </button>
        <Sidebar/>
      </div>
    <div className="flex-grow-1 p-3 bg-light">
    <div className='action_bar'>
      <button class="btn btn-primary" onClick={downloadPDF}><i class="bi bi-file-earmark-arrow-down"></i></button>
    </div>
    <div className="invoice-detail"  id="invoice-content">
            {/* Invoice header */}
            <div className="col-md-5 offset-md-7">
              <table className="table table-striped_ inv_head">
                <tbody>
                  <tr>
                    <td className="pritxt"><label className="form-label">Date</label> {new Date(data.created_at).toLocaleString()}</td>
                    <td>
                      <label className="form-label">Invoice #</label>
                      {data.invoice_number}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="greybg_">
              <table className="tabel" style={{width:"100%"}}>
                <tbody>
                  <tr>
                    <td className="w-50">
                      <p className=""><strong>RIDDHIMA AGENCY</strong></p>
                        <p>GSTIN:22IUNPK1701F1ZX</p>
                        <p>Number:+91 9589786775</p>
                        <p>email:riddhimaagency7@gmail.com</p>
                        <p>Shop 1,  Vrindavan Garden Daldal Seoni Mowa  ,</p>
                        <p>Raipur Chhattisgarh, 492005</p>
                    </td>
                    <td className="w-50">
                      <p><strong>{data.customer?.name}</strong></p>
                      <p>GSTIN:{data.customer?.gstin}</p>
                      <p>Phone:{data.customer?.phone}</p>
                      <p>GSTIN:{data.customer?.email}</p>
                      <p>Address:{data.customer?.address}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
      {/* <div className="invoice-header">
        <p><strong>Invoice Number:</strong> {data.invoice_number}</p>
        <p><strong>Customer ID:</strong> {data.customer_id}</p>
        <p><strong>Total Amount:</strong> {data.total_amount}</p>
        <p><strong>GST Amount:</strong> {data.gst_amount || 'N/A'}</p>
        <p><strong>Grand Total:</strong> {data.grand_total}</p>
        <p><strong>Status:</strong> {data.status}</p>
        <p><strong>Created At:</strong> {new Date(data.created_at).toLocaleString()}</p>
      </div> */}

      {/* Items table */}
      <div className="invoice-items">
        {data.items && data.items.length > 0 ? (
          <table className="table table-striped_">
            <thead>
              <tr>
                <th className="invhead">#</th>
                <th className="invhead">Product Name</th>
                <th className="invhead">MRP</th>
                <th className="invhead">Rate</th>
                {/* <th className="invhead">Discount %</th> */}
                <th className="invhead">Quantity Pcs.</th>
                <th className="invhead">SGST %</th>
                <th className="invhead">CGST %</th>
                {/* <th className="invhead">GST Amount</th> */}
                <th className="invhead">Amount</th>
                {/* <th className="invhead">Total</th> */}
              </tr>
            </thead>
            <tbody>
              {data.items.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.product.name}</td>
                  <td>{item.mrp}</td>
                  <td>{item.rate}</td>
                  {/* <td>{item.discount}</td> */}
                  <td>{item.quantity}</td>
                  <td>{item.gst_rate/2}</td>
                  <td>{item.gst_rate/2}</td>
                  {/* <td>{item.tax_amount}</td> */}
                  <td>{item.subtotal}</td>
                  {/* <td>{item.total}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No items found</p>
        )}
      </div>
         <div className="total col-md-4 offset-md-7">
              <table className="table table-striped_">
                <tbody>
                  <tr>
                    <td><label className="form-label">Total</label></td>
                    <td class="text-end">
                      {data.total_amount}
                    </td>
                  </tr>
                  <tr>
                    <td><span>SGST</span></td>
                    <td class="text-end">
                    {data.gst_amount/2}
                    </td>
                  </tr>
                  <tr>
                    <td><span>CGST</span></td>
                    <td class="text-end">
                    {data.gst_amount/2}
                    </td>
                  </tr>
                  <tr>
                    <td><span>Payble</span></td>
                    <td class="text-end">
                    {data.grand_total}
                    </td>
                  </tr>
                  {/* <tr>
                    <td><span>Paid</span></td>
                    <td class="text-end">
                       {data.status}    
                    </td>
                  </tr> */}
                </tbody>
              </table>
          </div>
    </div>
    </div>
    
    </div>      
    
    </>
  );
};

export default InvoiceDetail;
